.pageContainer {
    margin: 6vw 1.538vw;
}

.tabStyle {
    height: 6.41vw !important;
    width: 11.538vw !important;
    text-transform: capitalize !important;
    background-color: #EAF1F7 !important;
    box-shadow: 2px 2px 8px 0px #AEAEC080 !important;
    margin-right: 1rem !important;
    border-radius: 4px !important;
    font-size: 1.026vw !important;
    font-weight: 600 !important;
}
.tabStyleMobile {
  height: 2.5rem;
  text-transform: capitalize !important;
  background-color: #c5daec !important;
  box-shadow: 2px 2px 8px 0px #AEAEC080 !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  width: 100%;
  max-width: 100% !important;
  color: black !important;
  text-decoration: none !important;
}

/* Define a rule for the MuiOutlinedInput-root class */
.MuiOutlinedInput-root {
    /* Define a rule for fieldset inside MuiOutlinedInput-root */
    position: relative;
  }
  
  .MuiOutlinedInput-root fieldset {
    border-color: transparent;
  }
  
  .MuiOutlinedInput-root:hover fieldset {
    border-color: transparent;
  }
  
  .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: transparent;
    box-shadow: none;
  }
.reportMobileEachTab Link{
  height: 2.5rem;
  background: #EAF1F7;
  border-radius: 4px;
  box-shadow: 2px 2px 8px 0px #AEAEC080;
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}
